import React, { useState, useEffect } from 'react';

const MissIconicUSAReveal = () => {
  const [animationState, setAnimationState] = useState('initial');

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationState('glareExpanding');
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const handleGlareAnimationEnd = () => {
    if (animationState === 'glareExpanding') {
      setAnimationState('glareContracting');
    } else if (animationState === 'glareContracting') {
      setAnimationState('revealed');
    }
  };

  return (
    <div className="relative w-full h-screen bg-black overflow-hidden flex items-center justify-center">
      <style jsx>{`
        @keyframes expand {
          0% { transform: scale(0); opacity: 0; }
          100% { transform: scale(2); opacity: 1; }
        }
        @keyframes contract {
          0% { transform: scale(2); opacity: 1; }
          100% { transform: scale(0); opacity: 0; }
        }
        @keyframes fadeIn {
          0% { opacity: 0; transform: scale(0.9); }
          100% { opacity: 1; transform: scale(1); }
        }
        .glare {
          position: absolute;
          inset: 0;
          background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 70%);
          opacity: 0;
        }
        .glare.expanding {
          animation: expand 1.5s ease-out forwards;
        }
        .glare.contracting {
          animation: contract 1.5s ease-in forwards;
        }
        .content {
          opacity: 0;
        }
        .content.revealed {
          animation: fadeIn 1s ease-out forwards;
        }
        .button-text:hover {
          text-decoration: underline;
        }
      `}</style>
      <div 
        className={`glare ${animationState === 'glareExpanding' ? 'expanding' : ''} ${animationState === 'glareContracting' ? 'contracting' : ''}`}
        onAnimationEnd={handleGlareAnimationEnd}
      ></div>
      <div className={`content ${animationState === 'revealed' ? 'revealed' : ''} text-center`}>
        <h1 className="text-8xl font-bold mb-8 text-white">Miss Iconic USA</h1>
        <p className='text-white'>February 14-16, 2025 | NYFW</p>
        {/*<button className="text-2xl text-white button-text transition-all duration-300">
          Become Iconic: Enter Now
        </button>*/}
      </div>
    </div>
  );
};

export default MissIconicUSAReveal;